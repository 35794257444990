<template>
    <div id="erro">

            <div class="container col-12">
                <b-img center src="../../assets/pagina-nao-encontrada.png" alt="Center image"></b-img>

                <div class="text-erro"> 
                    <p> Opss!... Não conseguimos localizar a página que você desejava... :(
                    <br> Tente fazer
                    uma nova busca</p> <br>

                    <b-button class="btnErro" type="reset" variant=" m-3 " @click="cancelar()">Voltar para Home
                </b-button>
                </div>
            </div>

    </div>
</template>

<script>
export default {
  methods: {
    cancelar() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
.container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 900px;
    }
    
img {
    margin-top: 20%;
    width: 20%;
    height: 20%;
}

#erro {
  width: 100%;
  height: 100vh;
  // height: 55rem;
  overflow: hidden;
  background: #141f36;
}

.text-erro {
    margin-top: 1rem;
    text-align: center;
    color: #ececf6;
    font-size: 1.5rem;
}


.btnErro {
  background: linear-gradient(90deg, #2d6cea, #2d6cea, #2eaade);
  color: #fff;
  padding: 0.5rem 1rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  border-radius: 10rem;
  justify-self: center;
  position: relative;
  cursor: pointer;
  border: rgb(255 255 255 / 75%) dotted 2px;
}

</style>